import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Title from "../Blocks/title";
import { rem, useIsMobile } from "../styleUtils";
import { EColor, EIconGlyph, ESize } from "../../styleConstants";
import Button from "../Blocks/button";
import Spacer from "../Blocks/spacer";
import Paragraph from "../Blocks/paragraph";
import Translate from "../Translate";
import Checkbox from "../Blocks/checkbox";

const CookieConsentDialogContainer = styled.div`
    display: flex;
    background: rgba(32,32,32,0.84);
    position: fixed;
    z-index: 1001;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    justify-content: center;
    align-items: center;

    font-family: "Roboto";
`

const CookieConsentWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`
const CookieConsentContainer = styled.div<{ mobileLayout: boolean }>`
    display: flex;
    flex-direction: column;
    width: ${props => props.mobileLayout ? "100%" : rem(600)};
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #1e1e28;
    background-image: linear-gradient(to bottom, #1e1e28, #000000);
    border-radius: ${props => props.mobileLayout ? 0 : "15px"};
    padding: ${rem(40)} ${rem(40)} ${rem(30)} ${rem(40)};
`
const CookieConsentHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 40px;
    width: 100%;
`
const HeaderIconContainer = styled.div`
    display: flex;
    height: ${rem(40)};
    width: ${rem(40)};
    margin-right: ${rem(10)};

    div > button {
        cursor: pointer;
    }
`
const HeaderLogoContainer = styled.div`
    display: flex;
    height: ${rem(40)};
`
const CookieConsentContent = styled.div`
    display: flex;
    flex-direction: column;
`
const CookieSettingRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`
const CookieSettingTextContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const CookieSettingCheckboxContainer = styled.div`
    display: flex;
    width: ${rem(30)};
    height: ${rem(30)};
`
const CookieConsentButtonContainer = styled.div<{ mobileLayout: boolean }>`
    display: flex;
    flex-direction: column;

    div > button {
        width: 100%;
        cursor: pointer;
    }
`
const FooterPolicyContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const RejectAllContainer = styled.div`
    display: flex;
    height: 40px;
`

interface DataLayerEvent {
    eventCategory: string
    eventAction: string
    eventLabel: string
}

declare global {
    interface Window {
        showCookieConsentWindow: any;
    }
}

const getStorage = () => {
    const dataLayer: DataLayerEvent[] = JSON.parse(localStorage.getItem('delayedDatalayer'));
    return dataLayer ? dataLayer : [];
}

const setStorage = (data: DataLayerEvent) => {
    const state: DataLayerEvent[] = getStorage();
    state.push(data);
    localStorage.setItem('delayedDatalayer', JSON.stringify(state));
}

const setConsentCookie = (name: string, value: number, days: number) => {
    const cookie = {
        expires: "",
        domain: ""
    }

    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        cookie.expires = "; expires=" + date.toUTCString();
    }
    cookie.domain = "; domain=" + "." + location.hostname;

    return document.cookie = name + "=" + value + cookie.expires + cookie.domain + "; path=/";
}

const saveCookies = (allowPerformanceCookies: boolean, allowMarketingCookies: boolean) => {
    let consent = 1

    if (allowPerformanceCookies) {
        consent = consent | (1 << 1);
    }
    if (allowMarketingCookies) {
        consent = consent | (1 << 2);
    }

    setConsentCookie("ReadlyCookieConsent", consent, 365);

    setStorage({
        eventCategory: "Cookie Policy",
        eventAction: "Adjust Settings",
        eventLabel: "Save"
    });

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: "cookie_consent_submit"
    });
}

const AcceptAllCookiesHandler = (setShowCookieConsentDialog: React.Dispatch<React.SetStateAction<boolean>>) => {
    saveCookies(true, true);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: "cookie_consent_accept_all"
    });

    setShowCookieConsentDialog(false);

    // If current partner is Amexperks do not reload (it would break Amex handling)
    if (location.href.indexOf("amexperks") > -1) {
        return
    }

    // location.reload();
}

const RejectNonEssentialHandler = (setShowCookieConsentDialog: React.Dispatch<React.SetStateAction<boolean>>) => {
    saveCookies(false, false);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: "cookie_consent_reject_all"
    });

    setShowCookieConsentDialog(false);
    // location.reload();
}

const SaveCookieSettings = (allowPerformanceCookies: boolean, allowMarketingCookies: boolean, setShowCookieConsentDialog: React.Dispatch<React.SetStateAction<boolean>>) => {
    saveCookies(allowPerformanceCookies, allowMarketingCookies);

    setStorage({
        eventCategory: "Cookie Policy",
        eventAction: "Adjust Settings",
        eventLabel: "Save"
    });

    setShowCookieConsentDialog(false);
    // location.reload();
}

/**
 * Main cookie consent content with main description and accept all / reject non-essential
 */
const CookieMainContent = ({ setShowCookieSettings, setShowCookieConsentDialog }) => {
    const isMobile = useIsMobile();

    const enterSettings = () => {
        setStorage({
            eventCategory: "Cookie Policy",
            eventAction: "Adjust Settings",
            eventLabel: "Back"
        });

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: "cookie_consent_enter_adjust_settings"
        });

        setShowCookieSettings(true);
    }

    return (
        <>
            <Title size={6}>
                <Translate lokalise={true} path="dialog.cookies.title" />
            </Title>
            <Spacer height={ESize.default} />
            <Paragraph size={2}>
                <Translate lokalise={true} path="dialog.cookies.main-description" />
            </Paragraph>

            <Spacer height={ESize.medium} />

            <CookieConsentButtonContainer mobileLayout={isMobile}>
                <div onClick={() => AcceptAllCookiesHandler(setShowCookieConsentDialog)} data-testid="cookies-dialog-accept-all">
                    <Button
                        margin={ESize.none}
                        type="primary"
                        colorOverrided={EColor.Black}
                        backgroundOverride={EColor.SunglowYellow}
                        border={EColor.Black}
                        label={<Translate lokalise={true} path="dialog.cookies.accept-all-cookies" />}
                    />
                </div>
            </CookieConsentButtonContainer>

            <Spacer height={ESize.small} />

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                <Paragraph size={2}>
                    <Translate lokalise={true} path="dialog.cookies.or" />
                </Paragraph>
                <Spacer height={ESize.tiny} />
                <a style={{ color: EColor.SunglowYellow, cursor: "pointer" }} onClick={() => enterSettings()}>
                    <Translate lokalise={true} path="dialog.cookies.main.change-settings" />
                </a>
            </div>
        </>
    )
}

/**
 * Cookie consent settings content with checkboxes for toggling certain cookies.
 */
const CookieSettings = ({ allowPerformanceCookies, allowMarketingCookies, setAllowPerformanceCookies, setAllowMarketingCookies, setShowCookieConsentDialog }) => {
    const isMobile = useIsMobile();

    return (
        <>
            <CookieConsentContent>
                <Title size={6}>
                    <Translate lokalise={true} path="dialog.cookies.settings.title" />
                </Title>

                <Spacer height={ESize.medium} />

                <CookieSettingRow>
                    <CookieSettingTextContainer>
                        <Title size={3}>
                            <Translate lokalise={true} path="dialog.cookies.settings.functional.title" />
                        </Title>
                        <Paragraph size={2}>
                            <Translate lokalise={true} path="dialog.cookies.settings.functional.description" />
                        </Paragraph>
                    </CookieSettingTextContainer>

                    <CookieSettingCheckboxContainer>
                        <Checkbox checked={true} disabled={true} />
                    </CookieSettingCheckboxContainer>
                </CookieSettingRow>

                <Spacer height={ESize.medium} />

                <CookieSettingRow>
                    <CookieSettingTextContainer>
                        <Title size={3}>
                            <Translate lokalise={true} path="dialog.cookies.settings.performance.title" />
                        </Title>
                        <Paragraph size={2}>
                            <Translate lokalise={true} path="dialog.cookies.settings.performance.description" />
                        </Paragraph>
                    </CookieSettingTextContainer>

                    <CookieSettingCheckboxContainer>
                        <Checkbox checked={allowPerformanceCookies} stateHook={setAllowPerformanceCookies} />
                    </CookieSettingCheckboxContainer>
                </CookieSettingRow>

                <Spacer height={ESize.medium} />

                <CookieSettingRow>
                    <CookieSettingTextContainer>
                        <Title size={3}>
                            <Translate lokalise={true} path="dialog.cookies.settings.marketing.title" />
                        </Title>
                        <Paragraph size={2}>
                            <Translate lokalise={true} path="dialog.cookies.settings.marketing.description" />
                        </Paragraph>
                    </CookieSettingTextContainer>

                    <CookieSettingCheckboxContainer>
                        <Checkbox checked={allowMarketingCookies} stateHook={setAllowMarketingCookies} />
                    </CookieSettingCheckboxContainer>
                </CookieSettingRow>

            </CookieConsentContent>

            <Spacer height={ESize.medium} />

            <CookieConsentButtonContainer mobileLayout={isMobile}>
                <div onClick={() => SaveCookieSettings(allowPerformanceCookies, allowMarketingCookies, setShowCookieConsentDialog)}>
                    <Button
                        margin={ESize.none}
                        type="primary"
                        colorOverrided={EColor.Black}
                        backgroundOverride={EColor.SunglowYellow}
                        border={EColor.Black}
                        label={<Translate lokalise={true} path="dialog.cookies.settings.cta-save" />}
                        icon={EIconGlyph.check}
                    />
                </div>
            </CookieConsentButtonContainer>
        </>
    )
}

/**
 * Cookie consent dialog.
 * @param showCookieDialog - Should this dialog be shown?
 * @returns CookieConsentDialog component
 */
const CookieConsentDialog = ({
    showCookieDialog,
    userCountry
}: {
    showCookieDialog?: boolean,
    userCountry?: string
}) => {
    const isMobile = useIsMobile();

    const [showCookieSettings, setShowCookieSettings] = useState<boolean>(false);
    const [showCookieConsentDialog, setShowCookieConsentDialog] = useState<boolean>(showCookieDialog);

    const [allowPerformanceCookies, setAllowPerformanceCookies] = useState<boolean>(true);
    const [allowMarketingCookies, setAllowMarketingCookies] = useState<boolean>(true);

    const userIsNL = userCountry === "NL";
    // Only FR requires the Reject-non essential cookies.
    const userIsFR = userCountry === "FR";

    const exitSettings = () => {
        // Temporary tracking before a user has accepted cookie policy.
        setStorage({
            eventCategory: "Cookie Policy",
            eventAction: "Adjust Settings",
            eventLabel: "Back"
        });
        setShowCookieSettings(false);
    }

    useEffect(() => {
        if (showCookieConsentDialog) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: "cookie_consent_shown"
            });
        }
    }, [showCookieConsentDialog]);

    useEffect(() => {
        // Temporary tracking before a user has accepted cookie policy.
        setStorage({
            eventCategory: "Cookie Policy",
            eventAction: "Adjust Settings",
            eventLabel: `Toggle Performance: ${allowPerformanceCookies ? "on" : "off"}`
        });
    }, [allowPerformanceCookies]);

    useEffect(() => {
        // Temporary tracking before a user has accepted cookie policy.
        setStorage({
            eventCategory: "Cookie Policy",
            eventAction: "Adjust Settings",
            eventLabel: `Toggle Marketing: ${allowMarketingCookies ? "on" : "off"}`
        });
    }, [allowMarketingCookies]);

    useEffect(() => {
        showCookieConsentDialog ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'unset';
    }, [showCookieConsentDialog]);

    useEffect(() => {
        window.showCookieConsentWindow = () => {
            setShowCookieConsentDialog(!showCookieConsentDialog);
        }
    }, [showCookieConsentDialog]);

    return (
        <>
            {showCookieConsentDialog ?
                <CookieConsentDialogContainer>
                    <CookieConsentWrapper>
                        <CookieConsentContainer mobileLayout={isMobile}>
                            <CookieConsentHeader>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    {showCookieSettings ?
                                        <HeaderIconContainer>
                                            <div onClick={() => exitSettings()}>
                                                <Button
                                                    margin={ESize.none}
                                                    type="tertiary"
                                                    colorOverrided={EColor.FloralWhite}
                                                    backgroundOverride={EColor.MarlinGray}
                                                    border={EColor.Black}
                                                    label={""}
                                                    icon={EIconGlyph.chevron_left}
                                                />
                                            </div>
                                        </HeaderIconContainer>
                                        : null
                                    }
                                    <HeaderLogoContainer>
                                        <img src="https://dnjpbxg2wcpr8.cloudfront.net/web/readly_logo_light.svg" alt="Readly Logo" />
                                    </HeaderLogoContainer>
                                </div>
                                {userIsFR ?
                                    <RejectAllContainer>
                                        <div onClick={() => RejectNonEssentialHandler(setShowCookieConsentDialog)}>
                                            <a style={{ color: EColor.MountainGray, cursor: "pointer" }}>
                                                <Translate lokalise={true} path="dialog.cookies.reject-non-essential-cookies" />
                                            </a>
                                        </div>
                                    </RejectAllContainer>
                                    :
                                    null
                                }
                            </CookieConsentHeader>

                            <Spacer height={ESize.small} />

                            <CookieConsentContent>
                                {showCookieSettings ?
                                    <CookieSettings
                                        allowPerformanceCookies={allowPerformanceCookies}
                                        allowMarketingCookies={allowMarketingCookies}
                                        setAllowPerformanceCookies={setAllowPerformanceCookies}
                                        setAllowMarketingCookies={setAllowMarketingCookies}
                                        setShowCookieConsentDialog={setShowCookieConsentDialog}
                                    />
                                    :
                                    <CookieMainContent
                                        setShowCookieSettings={setShowCookieSettings}
                                        setShowCookieConsentDialog={setShowCookieConsentDialog}
                                    />
                                }
                            </CookieConsentContent>

                            <Spacer height={ESize.medium} />

                            <FooterPolicyContainer>
                                <Paragraph size={1}>
                                    <Translate lokalise={true} path="dialog.cookies.cookie-policy-disclaimer" interpolation={{ policyLink: `${window.location.origin}/cookie` }} />
                                </Paragraph>
                                {!userIsNL ? // NL doesn't have a privacy policy.
                                    <Paragraph size={1}>
                                        <Translate lokalise={true} path="dialog.cookies.cookie-privacy-policy" interpolation={{ policy_disclaimer: `${window.location.origin}/privacy` }} />
                                    </Paragraph>
                                    :
                                    null
                                }
                            </FooterPolicyContainer>
                        </CookieConsentContainer>

                    </CookieConsentWrapper>

                </CookieConsentDialogContainer>
                : null
            }
        </>
    );

};

export default CookieConsentDialog;